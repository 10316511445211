import React from "react";
import WorkJobDetailsHeader from "../../WorkJobDetails/WorkJobDetailsHeader";
import ActionBar from "../../WorkJobDetails/ActionBar";
import WorkJobTabs from "../../WorkJobDetails/WorkJobTabs";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchJobDetail } from "../../../services/api/workOrder";
import { useDispatch } from "react-redux";
import { setWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";

const JobDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    isLoading: isjobDetailDataLoading,
    error: isjobDetailDataError,
    data: jobDetailData,
    // refetch: fetchJobDetail,
  } = useQuery({
    queryKey: ["jobDetail"],
    queryFn: () => fetchJobDetail(id),
    enabled: !!id,
  });

  dispatch(setWorkOrderDetail(jobDetailData?.data));

  return (
    <>
      <WorkJobDetailsHeader />
      <ActionBar />
      <div className="bg-light h-full py-2">
        <WorkJobTabs />
      </div>
    </>
  );
};

export default JobDetails;
