import axios from "../../lib/axiosInstance";
export function fetchCategories(payload) {
  return axios.get("categories", payload).then((res) => res.data);
}

export function fetchSubCategories(id) {
  return axios
    .get(`categories/subCategoriesByCategoryId/${id}`)
    .then((res) => res.data);
}

export function fetchVendors(id) {
  return axios
    .get(`categories/findAllVendorsByCategoryId/${id}`)
    .then((res) => res.data);
}
