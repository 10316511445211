import React from "react";
import { useParams } from "react-router-dom";
import { fetchWorkOrderPaymetDetails } from "../../services/api/workOrder";
import { useQuery } from "@tanstack/react-query";

const PaymentCard = ({ totalAmount, status }) => {
  const { id } = useParams();

  const {
    isLoading,
    error,
    data: workOrderPaymentData,
  } = useQuery({
    queryKey: ["workOrderPayment"],
    queryFn: () => fetchWorkOrderPaymetDetails(id),
    enabled: !!id,
  });

  const payment = workOrderPaymentData?.data;

  return (
    <div className="shadow-md rounded-md py-3 px-4">
      <div className="flex">
        <i className="fa-solid fa-coins text-3xl text-primary"></i>
        <div className="ml-2  mt-2">
          <p className="font-normal text-sm text-dark">Payment: {status}</p>

          <p className="text-sm mt-1 text-primary">
            $ {payment?.totalWorkOrderCost?.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
