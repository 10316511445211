import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Deliverables = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Deliverables</h6>
      </div>
      <div>
        <p>Misc (0)</p>
        <div className="flex items-center mt-4 cursor-pointer">
          <i className="fa-solid fa-plus text-secondary"></i>
          <p className="text-secondary  ml-1"> Add Files</p>
        </div>
        <div className="flex items-center mt-3 cursor-pointer">
          <i className="fa-solid fa-plus text-secondary"></i>
          <p className="text-secondary  ml-1"> Add Folder</p>
        </div>
      </div>
    </BaseCard>
  );
};

export default Deliverables;
