import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";

const ReadyToGoModal = ({
  readyToGoModalOpen,
  setReadyToGoModalOpen,
  tasks,
}) => {
  const params = useParams();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [reasonType, setReasonType] = useState("");

  const {
    isLoading: isTaskUpdateLoading,
    error: isTaskUpdateError,
    data: updatedTaskData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const handleReadyToGo = async () => {
    const task = tasks?.find((task) => task.taskType === "START_TIME");

    try {
      let payload = {
        id: task.completedTask?.id,
        isCompleted: true,
      };

      const updatedTask = await updateTaskMutation(payload);

      updateWorkOrder();

      setReadyToGoModalOpen(false);
    } catch (error) {
      dispatch(
        showToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };
  return (
    <BaseModal
      open={readyToGoModalOpen}
      close={() => {}}
      style={{ width: "600px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">Ready to go</h6>
        <p>Have you got all necessary tools as per WO requirement?</p>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
          fullWidth={false}
          onClick={() => setReadyToGoModalOpen(false)}
        >
          <p>Cancel</p>
        </BaseButton>
        <BaseButton
          disabled={isTaskUpdateLoading}
          isLoading={isTaskUpdateLoading}
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleReadyToGo}
        >
          Confirm
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default ReadyToGoModal;
