import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Equipments = () => {
  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Equipments</h6>
      </div>
      <TextField
        placeholder="Search"
        className="bg-light rounded-md"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <i className="fa-solid fa-search text-[#B0B0B0]"></i>
            </InputAdornment>
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent", // Default border color
            },
            "&:hover fieldset": {
              borderColor: "transparent", // Border color when hovered
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent", // Border color when focused
            },
          },
        }}
      />
      <div className="flex flex-wrap mt-5">
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          allen wranches
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          Basic Hand Tools
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          ethernet patch cable
        </p>

        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          Label Maker
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          Crimper
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          face mask
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          Patch Cable
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          soldering iron
        </p>
        <p className="bg-light py-1 px-4 rounded-md mr-7 mb-5 text-sm">
          Shop Towels
        </p>
      </div>
      <p className="text-primary text-sm cursor-pointer">View More</p>
    </BaseCard>
  );
};

export default Equipments;
