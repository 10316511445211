import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import Map from "../../../components/Map/Map";

const LocationMap = ({ location }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-1">
        <h6 className="text-dark">Location</h6>
      </div>
      <p>
        {location?.addressLine1}{" "}
        <span className="italic">({location?.locationType})</span>
      </p>
      <div className="mt-3">
        <Map latitude={location?.latitude} longitude={location?.longitude} />
      </div>
    </BaseCard>
  );
};

export default LocationMap;
