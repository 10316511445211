import React, { useEffect, useState } from "react";
import BaseWrapper from "../../components/ui/BaseWrapper";
import dayjs from "dayjs";
import WorkOrderStatus from "../../components/WorkOrderStatus";
import BaseDataTable from "../../components/ui/BaseDataTable";
import FilterChip from "../../components/FilterChip";
import { useNavigate } from "react-router-dom";
import BaseRating from "../../components/ui/BaseRating";

const FindJobTable = ({ jobs, setJobsFilters, jobsCount }) => {
  const [filters, setFilters] = useState("AVAILABLE");
  const [statusCount, setStatusCount] = useState({});

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/find-job/${id}`);
  };

  const openGooglMapLocation = (latitude, longitude) => {
    window.open("https://maps.google.com?q=" + latitude + "," + longitude);
  };

  const data = jobs || [];
  const columnWidths = {
    ID: "206px",
    "Job Title": "312px",
    Location: "18%",
    "Type of work": "14%",
    Schedule: "18%",
    Pay: "18%",
    "Type of work": "16%",
    Distance: "14%",
    Company: "14%",
    "Buyer Rating": "18%",
    Requests: "14%",
    Status: "22%",

    // "Additional Column": "70%",
  };
  const COLUMNS = [
    {
      label: "ID",
      renderCell: (item) => (
        <p
          className="table-font-size font-normal text-primary cursor-pointer"
          onClick={() => handleNavigate(item.id)}
        >
          {item.id}
        </p>
      ),
      pinLeft: false,
      sort: { sortKey: "ID" },
    },
    {
      label: "Job Title",
      renderCell: (item) => (
        <>
          <p
            className="table-font-size text-primary cursor-pointer"
            onClick={() => handleNavigate(item.id)}
          >
            {item.title}
          </p>
        </>
      ),
      // sort: { sortKey: "TITLE" },
    },
    {
      label: "Location",
      renderCell: (item) => (
        <p
          className="table-font-size cursor-pointer text-primary"
          onClick={() =>
            openGooglMapLocation(item.address.latitude, item.address.longitude)
          }
        >{`${item.address.addressLine1}, ${item.address.country}`}</p>
      ),
      resize: true,
      // sort: { sortKey: "LOCATION" },
    },
    {
      label: "Type of work",
      renderCell: (item) => (
        <p className="table-font-size">{`${
          item?.category?.category || "not specified"
        }`}</p>
      ),
      // sort: { sortKey: "LOCATION" },
    },

    {
      label: "Schedule",
      renderCell: (item) => (
        <div>
          <p className={`text-primary text-xs`}>
            {item.scheduleType === "HARD_START"
              ? "Hard Time"
              : item.scheduleType === "WORK_BETWEEN"
              ? "Work Between"
              : ""}
          </p>
          <p className={`table-font-size`}>
            {dayjs(item.orderStartDate).format("MM/DD/YYYY")}

            <span> at </span>

            {dayjs(item.orderStartTime).format("hh:mm A")}
            <span> ({item.timeZone})</span>
          </p>
        </div>
      ),
      // sort: { sortKey: "SCHEDULE" },
    },
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          {item.paymentType === "HOURLY" && (
            <p className={`table-font-size`}>
              {item?.minimumHours?.toFixed(2)}hours @ ${item.hourlyRate || 0}/hr
            </p>
          )}
          <p className=" text-xs">
            {item.paymentType === "HOURLY" && "hourly"}
            <span className="ml-1 text-primary"> Payment Terms</span>
          </p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Distance",
      renderCell: (item) => (
        <div>
          {item?.distance && (
            <p className="table-font-siz">{item?.distance?.toFixed(2)} km</p>
          )}
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Company",
      renderCell: (item) => (
        <div>
          <p className="table-font-size">
            {item?.createdBy?.company?.name || ""}
          </p>
        </div>
      ),

      // sort: { sortKey: "PAY" },
    },
    {
      label: "Buyer Rating",
      renderCell: (item) => (
        <div className="flex items-center justify-center border border-primary w-20 py-1 rounded-full">
          <BaseRating style={{ fontSize: "13px" }} />
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Requests",
      renderCell: (item) => (
        <p className="table-font-size">{item?._count?.offers || 0}</p>
      ),
      // sort: { sortKey: "PAY" },
    },
    {
      label: "Status",
      renderCell: (item) => (
        <WorkOrderStatus
          status={
            filters === "AVAILABLE" ||
            filters === "ASSIGNED" ||
            filters === "INPROGRESS" ||
            filters === "WORKDONE" ||
            filters === "INCOMPLETE"
              ? item.status
              : item?.offers?.isCounter
              ? "COUNTER"
              : item?.offers?.isCounter !== true
              ? "REQUESTED"
              : item?.offers?.status
          }
        />
      ),
      // sort: { sortKey: "STATUS" },
    },
    /* 
    

    
    {
      label: "Pay",
      renderCell: (item) => (
        <div>
          <p className="text-primary text-xs">$/hour</p>
          <p className="text-sm">3.00 hours @ ${item.payAmount}/hr</p>
        </div>
      ),
      // sort: { sortKey: "PAY" },
    },
  
    
    
  

     */
  ];

  return (
    <BaseWrapper>
      {/* <div className="bg-white rounded-lg p-5 shadow-md my-4">
        <div className="flex">
          <div className="flex items-center border-r-[1px] border-[#8C8C8C] pr-5">
            <i className="fa-solid fa-circle-plus text-secondary text-[22px]"></i>
            <p className="text-secondary ml-2">Add Filter</p>
          </div>
          <div className="flex items-center pl-5">
            <p className="font-medium">
              Assigned Provider:{" "}
              <span className="font-light">Samuel Nyandoro</span>
            </p>
            <i className="ml-3 mb-[2px] fa-solid fa-chevron-down"></i>
          </div>
        </div>
      </div> */}

      <div className="py-4 flex">
        <FilterChip
          filter="Available"
          count={jobsCount?.AVAILABLE || 0}
          active={filters === "AVAILABLE" ? true : false}
          onClick={() => {
            setFilters("AVAILABLE");
            setJobsFilters({
              status: "AVAILABLE",
            });
          }}
        />

        <div className="ml-8">
          <FilterChip
            filter="Routed"
            count={jobsCount?.ROUTED || 0}
            active={filters === "DRAFT" ? true : false}
            onClick={() => {
              setFilters("DRAFT");
              setJobsFilters({
                status: "ROUTED",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Counter"
            count={jobsCount?.COUNTER || 0}
            active={filters === "COUNTER" ? true : false}
            onClick={() => {
              setFilters("COUNTER");
              setJobsFilters({
                status: "PENDING",
                isCounter: true,
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Requested"
            count={jobsCount?.REQUESTED || 0}
            active={filters === "REQUESTED" ? true : false}
            onClick={() => {
              setFilters("REQUESTED");
              setJobsFilters({
                status: "PENDING",
                isCounter: false,
              });
            }}
          />
        </div>

        <div className="ml-8">
          <FilterChip
            filter="Assigned"
            count={jobsCount?.ASSIGNED || 0}
            active={filters === "ASSIGNED" ? true : false}
            onClick={() => {
              setFilters("ASSIGNED");

              setJobsFilters({
                status: "ASSIGNED",
              });
            }}
          />
        </div>

        <div className="ml-8">
          <FilterChip
            filter="Work Done"
            count={jobsCount?.WORKDONE || 0}
            active={filters === "WORKDONE" ? true : false}
            onClick={() => {
              setFilters("WORKDONE");

              setJobsFilters({
                status: "WORKDONE",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Incomplete"
            count={jobsCount?.INCOMPLETE || 0}
            active={filters === "INCOMPLETE" ? true : false}
            onClick={() => {
              setFilters("INCOMPLETE");
              setJobsFilters({
                status: "INCOMPLETE",
              });
            }}
          />
        </div>
        <div className="ml-8">
          <FilterChip
            filter="Declined"
            count={jobsCount?.DECLINED || 0}
            active={filters === "DECLINED" ? true : false}
            onClick={() => {
              setFilters("DECLINED");

              setJobsFilters({
                status: "DECLINED",
              });
            }}
          />
        </div>
        {/*  <div className="ml-8">
          <FilterChip
            filter="All"
            // count={jobsCount?.DECLINED || 0}
            active={filters === "DECLINED" ? true : false}
            onClick={() => {
              setFilters("");

              setJobsFilters({
                status: "",
              });
            }}
          />
        </div> */}
      </div>

      <BaseDataTable
        columns={COLUMNS}
        nodes={data}
        columnWidths={columnWidths}
        lastColFix={false}
      />
    </BaseWrapper>
  );
};

export default FindJobTable;
