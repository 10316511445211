import React from "react";
import UserDetailsCard from "./UserDetailsCard";
import LocationCard from "./LocationCard";
import TimeLoggedCard from "./TimeLoggedCard";
import TitleCard from "./TitleCard";
import BaseWrapper from "../../components/ui/BaseWrapper";
import { useSelector } from "react-redux";
import ScheduleCard from "./ScheduleCard";
import useAuth from "../../hooks/useAuth";
import PaymentCard from "./PaymentCard";
import WorkingHoursCard from "./WorkingHoursCard";

const WorkJobDetailsHeader = () => {
  const { authRole } = useAuth();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  return (
    <div className="border-b border-zinc-300 py-4 sticky top-[41px] bg-white z-10">
      <BaseWrapper>
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-5 h-full w-full flex items-center">
            <TitleCard
              publishedDate={workOrderDetail?.createdAt}
              title={workOrderDetail?.title}
              id={workOrderDetail?.id}
              status={workOrderDetail?.status}
            />
            <div></div>
          </div>

          <div className="col-span-7 ml-4">
            <div className="grid grid-cols-3 gap-8">
              {workOrderDetail?.status === "WORKDONE" ||
              workOrderDetail?.status === "APPROVED" ? (
                <WorkingHoursCard totalHours={workOrderDetail?.totalHours} />
              ) : (
                <ScheduleCard
                  timeLogs={workOrderDetail?.timeLogs}
                  tasks={workOrderDetail?.tasks}
                  status={workOrderDetail?.status}
                  assignedTo={workOrderDetail?.assignedTo}
                  schedule={{
                    timeZone: workOrderDetail?.timeZone,
                    scheduleType: workOrderDetail?.scheduleType,
                    orderStartDate: workOrderDetail?.orderStartDate,
                    orderStartTime: workOrderDetail?.orderStartTime,
                    orderEndDate: workOrderDetail?.orderEndDate,
                    orderEndTime: workOrderDetail?.orderEndTime,
                    confirmedWorkOrderAt: workOrderDetail?.tasks?.find(
                      (task) => task.taskType === "START_TIME"
                    )?.completedTask?.text,
                  }}
                />
              )}
              {/* {authRole === "FREELANCER" ? ( */}

              {/* )  */}

              {/* : ( */}
              {/* <TimeLoggedCard /> */}
              {/* )} */}
              {workOrderDetail?.status === "WORKDONE" ||
              workOrderDetail?.status === "APPROVED" ? (
                <PaymentCard
                  status={
                    workOrderDetail?.status === "WORKDONE"
                      ? "Estimated"
                      : workOrderDetail?.status === "APPROVED"
                      ? "Pending"
                      : ""
                  }
                  payment={{
                    totalAmount: workOrderDetail?.totalAmount,
                  }}
                />
              ) : (
                <LocationCard location={workOrderDetail?.address} />
              )}
              <UserDetailsCard
                assignedTo={workOrderDetail?.assignedTo}
                authRole={authRole}
                createdBy={workOrderDetail?.createdBy}
                workOrderManager={workOrderDetail?.workOrderManager}
                offersCount={workOrderDetail?.offersCount}
              />
            </div>
          </div>
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkJobDetailsHeader;
