import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import BaseDragDrop from "../../../components/ui/BaseDragDrop";
import BaseButton from "../../../components/ui/BaseButton";

const Documents = () => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-1 text-dark">Client Documents</h6>
      </div>

      <div className="grid grid-cols-2 gap-8 py-3">
        <div>
          <div className="bg-[#F6F6F6] rounded-md mt-4">
            <div className="flex justify-between px-4 py-2">
              <div className="flex items-center">
                <img
                  className="h-14	w-14 rounded-md"
                  src="https://as2.ftcdn.net/v2/jpg/05/21/18/03/1000_F_521180377_2iAVJqBQSo3cgKaVp8vMBR8asrC61DoU.jpg"
                />
                <div className="ml-3">
                  <p className="font-normal">image_9.png</p>
                  <p>7/23/2024 at 2:43 AM EDT</p>
                  <p>
                    Uploaded by: 
                    <span className="text-secondary">Istkhar Hussain</span>
                  </p>
                </div>
              </div>
              <div>
                <i className="fa-solid fa-download"></i>
                <i className="fa-solid fa-trash-can ml-3"></i>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-[#F6F6F6] rounded-md mt-4">
            <div className="flex justify-between px-4 py-2">
              <div className="flex items-center">
                <img
                  className="h-14	w-14 rounded-md"
                  src="https://as2.ftcdn.net/v2/jpg/05/21/18/03/1000_F_521180377_2iAVJqBQSo3cgKaVp8vMBR8asrC61DoU.jpg"
                />
                <div className="ml-3">
                  <p className="font-normal">image_9.png</p>
                  <p>7/23/2024 at 2:43 AM EDT</p>
                  <p>
                    Uploaded by: 
                    <span className="text-secondary">Istkhar Hussain</span>
                  </p>
                </div>
              </div>
              <div>
                <i className="fa-solid fa-download"></i>
                <i className="fa-solid fa-trash-can ml-3"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default Documents;
