import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseSelect from "../../../components/ui/BaseSelect";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { markWorkOrderInComplete } from "../../../services/api/workOrder";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

const MarkIncompleteModal = ({
  markIncompleteModalOpen,
  setMarkIncompleterModalOpen,
}) => {
  const params = useParams();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [reasonType, setReasonType] = useState("");

  const {
    isLoading: isMarkWorkOrderInCompleteLoading,
    error: isMarkWorkOrderInCompleteError,

    mutateAsync: markWorkOrderInCompleteMutation,
  } = useMutation({
    mutationFn: markWorkOrderInComplete,
    onSuccess: (data) => {
      console.log("response ", data);
    },
  });

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleReasonTypeChange = (e) => {
    console.log(e.target.value);
    setReasonType(e.target.value);
  };

  const markWorkOrderInCompleteApi = async () => {
    let payload = {
      workOrderId: parseInt(params.id),
      reasonType: reasonType,
      reason,
    };
    try {
      const response = await markWorkOrderInCompleteMutation(payload);
      if (response?.status === "error") {
        console.log(":res", response);
        dispatch(
          showToast({
            message: response?.error?.response?.data?.message,
            type: "error",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Marked Incomplete",
            type: "success",
          })
        );
        updateWorkOrder();
        setMarkIncompleterModalOpen(false);
      }
    } catch (error) {}
  };
  return (
    <BaseModal open={markIncompleteModalOpen} close={() => {}}>
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">Mark Incomplete</h6>
        <p>
          Notify the Provider of missing requirments necessary to complete this
          job. Work order will revert to assigned status
        </p>
        <div className="mt-2">
          <BaseSelect
            label="Tell Tech Domain why you’re marking this incomplete"
            labelShrink={false}
            isDefaultField={false}
            required={true}
            options={[
              {
                label: "Missing/Incorrect Deliverables",
                value: "MISSING_INCORRECT_DELIVERABLES",
              },
              { label: "Time/Pay Incorrect", value: "TIME_PAY_ICORRECT" },
              {
                label: "Missing Sign-Off Sheet",
                value: "MISSING_SIGN_OFF_SHEET",
              },
              {
                label: "Required Parts Not Returned",
                value: "REQUIRED_PARTS_NOT_RETURNED",
              },
              { label: "Revisit Requested", value: "REVISIT_REQUESTED" },
            ]}
            onChange={(e) => handleReasonTypeChange(e)}
          />
        </div>

        <div className="mt-4">
          <p className="font-normal mb-1">
            Tell the provider why you’re marking this incomplete
          </p>
          <textarea
            value={reason}
            className="bg-white shadow-md w-full rounded-lg h-40 resize-none p-2 text-xs text-[#686868] font-[300]"
            onChange={handleReasonChange}
          />
        </div>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 mt-8"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-[6px] bg-[#E8E8E8] text-dark"
          fullWidth={false}
          onClick={() => setMarkIncompleterModalOpen(false)}
        >
          <p>Cancel</p>
        </BaseButton>
        <BaseButton
          disabled={isMarkWorkOrderInCompleteLoading}
          isLoading={isMarkWorkOrderInCompleteLoading}
          className="py-[5px] px-[8px] ml-4"
          color="primary"
          fullWidth={false}
          onClick={markWorkOrderInCompleteApi}
        >
          Mark Incomplete
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default MarkIncompleteModal;
