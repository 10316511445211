import React, { useState } from "react";
import BaseModal from "../../../components/ui/BaseModal";
import BaseButton from "../../../components/ui/BaseButton";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { showToast } from "../../../store/toast/toastSlice";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../../services/api/task";
import { handleGetLocation } from "../../../utils/helpers";
import { checkIn } from "../../../services/api/timeLog";

const CheckInModal = ({ checkInModalOpen, setCheckInModalOpen, tasks }) => {
  const params = useParams();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const {
    isLoading: isCheckInLoading,
    error: isCheckInError,
    data: timeLogData,
    mutateAsync: checkInMutation,
  } = useMutation({
    mutationFn: checkIn,
    enabled: false,
  });

  const handleCheckIn = async (id) => {
    console.log("params.id", params.id);
    try {
      // Get the user's location
      const location = await handleGetLocation();

      const task = tasks?.find((task) => task.taskType === "CHECK_IN");

      // Create the payload with correct latitude and longitude
      let payload = {
        checkInLatitude: location?.latitude,
        checkInLongitude: location?.longitude, // Corrected key
        workOrderId: parseInt(params.id),
        taskId: task.completedTask.id,
      };

      // Update the task and check-in
      // await updateTaskApi(id, isCompleted);
      const response = await checkInMutation(payload);

      // After successful task update and check-in, update the state
      updateWorkOrder();
      setCheckInModalOpen(false);
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle the error if necessary
    }
  };
  return (
    <BaseModal
      open={checkInModalOpen}
      close={() => {}}
      style={{ width: "600px" }}
    >
      <div className="container">
        <h6 className="text-dark border-bottom mb-4 pb-1">Check In</h6>
        <p>Please make sure that you are already on site before checking in.</p>
      </div>
      <div
        className="flex items-center justify-center bg-white py-6 mt-3"
        style={{
          boxShadow: "5px 0px 14px 5px rgba(0, 0, 0, 0.07)",
        }}
      >
        <BaseButton
          className="py-[5px] px-2 bg-[#E8E8E8] text-dark"
          fullWidth={false}
          onClick={() => setCheckInModalOpen(false)}
        >
          <p>Cancel</p>
        </BaseButton>
        <BaseButton
          /* disabled={isCheckInLoading}
          isLoading={isCheckInLoading} */
          className="py-[5px] px-4 ml-4"
          color="primary"
          fullWidth={false}
          onClick={handleCheckIn}
        >
          Confirm
        </BaseButton>
      </div>
    </BaseModal>
  );
};

export default CheckInModal;
