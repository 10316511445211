import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";

import BaseButton from "../../components/ui/BaseButton";
import BaseCard from "../../components/ui/BaseCard";
import BaseTextField from "../../components/ui/BaseTextField";
import { companySignup } from "../../services/api";
import BaseCheckbox from "../../components/ui/BaseCheckbox";
import { Link } from "react-router-dom";
import OtpModal from "../OtpModal";
import SearchLocation from "../../components/SearchLocation/SearchLocation";
import { useDispatch } from "react-redux";
import { showToast } from "../../store/toast/toastSlice";

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  phoneNumber: yup
    .string("Enter your phone number")
    .required("Phone number is required"),
  referralSource: yup
    .string("Enter your source")
    .required("Source is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),

  address: yup.string("Enter your address").required("Address is required"),
});

const TechnicianSignupForm = () => {
  const [isIndividual, setIsIndividual] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const {
    isPending: isSignupLoading,
    status,
    error,
    mutateAsync: signupMutation,
  } = useMutation({
    mutationFn: companySignup,
    onSuccess: (data) => {
      signupSuccessful(data);
    },
    onError: (error) => {
      dispatch(
        showToast({
          message: error?.response?.data?.message,
          type: "error",
        })
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      name: "",
      phoneNumber: "",
      email: "",
      password: "",
      referralSource: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const payload = {
          ...values,
          address: selectedLocation,
        };

        await signupMutation(payload);
      } catch (error) {}
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleCheckboxChange = (type) => {
    if (type === "individual" && !isIndividual) {
      setIsIndividual(true);
    } else if (type === "company" && isIndividual) {
      setIsIndividual(false);
    }
  };

  const signupSuccessful = (user) => {
    setOpen(true);
  };

  const handleLocationSearch = (address) => {
    setSelectedLocation({
      addressLine1: address.completeAddress,
      latitude: address?.latitude,
      longitude: address?.longitude,
      state: address.state,
      zipCode: address.zipCode,
      country: address.country,
      city: address.city || "",
    });
  };

  return (
    <>
      <div className="grid grid-cols-6 mx-7">
        <div></div>
        <div className="col-span-4 z-20">
          <BaseCard className="p-8">
            <p className="text-sm text-gray-950">SIGN UP</p>
            <h2 className="mt-3">
              Join the Leading Marketplace for IT Field Service.
            </h2>
            <p className="mt-7 mb-2 leading-normal font-normal">
              Number of service providers in your organization?
              <span className="text-secondary font-semibold"> *</span>
            </p>

            <form>
              <BaseCheckbox
                onChange={() => handleCheckboxChange("individual")}
                checked={isIndividual}
                label="Just me (I’m an Independent Service Provider)"
              />
              <br />
              <BaseCheckbox
                onChange={() => handleCheckboxChange("company")}
                checked={!isIndividual}
                label="More than one (I operate a Service Company with more than one provider)"
              />
              <div className="grid grid-cols-3 gap-x-8 gap-y-8 mt-14">
                {!isIndividual && (
                  <div className="grid grid-cols-3 col-span-3 gap-x-6">
                    <BaseTextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      id="company"
                      name="name"
                      label="Your Company Name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                    <p className="col-span-2 text-sm text-gray-950 mt-1">
                      <span className="font-medium text-gray-950">Note:</span>{" "}
                      This account will require you to provide associated{" "}
                      <span className="text-secondary">
                        Registration Number.
                      </span>{" "}
                      If you are an individual provider looking for work, please
                      select Just me in the question above.
                    </p>
                  </div>
                )}
                <BaseTextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <BaseTextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  required
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />

                <BaseTextField
                  fullWidth
                  required
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone number"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />

                <BaseTextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />

                <BaseTextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
                <div>
                  <SearchLocation
                    required
                    name="address"
                    labelShrink={true}
                    isDefaultField={true}
                    label="Address"
                    onChange={formik.handleChange}
                    onPlaceSelected={handleLocationSearch}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </div>

                <BaseTextField
                  required
                  fullWidth
                  id="referralSource"
                  name="referralSource"
                  className="col-span-3"
                  label="What brings you to Tech Domain?"
                  value={formik.values.referralSource}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.referralSource &&
                    Boolean(formik.errors.referralSource)
                  }
                  helperText={
                    formik.touched.referralSource &&
                    formik.errors.referralSource
                  }
                />
                <div className="col-span-3">
                  <p className="text-sm font-semibold">Terms of service</p>
                  <BaseCheckbox
                    label={
                      <p className="text-sm">
                        I agree to Tech Domain's{" "}
                        <Link className="underline underline-offset-2">
                          terms of service
                        </Link>
                      </p>
                    }
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <Link>
                  <BaseButton
                    isLoading={isSignupLoading}
                    onClick={formik.handleSubmit}
                    className="mt-7"
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <p className="text-sm font-semibold	font-semibold text-white px-7">
                      Sign Up
                    </p>
                  </BaseButton>
                </Link>
              </div>
            </form>
          </BaseCard>
        </div>
      </div>

      <OtpModal open={open} setOpen={setOpen} email={formik.values.email} />
    </>
  );
};

export default TechnicianSignupForm;
