import React from "react";
import BaseButton from "../../../components/ui/BaseButton";
import BaseWrapper from "../../../components/ui/BaseWrapper";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ROLES } from "../../../utils/variables";
const WorkOrderListingHeader = () => {
  const naviagte = useNavigate();
  const { authRole } = useAuth();

  const handleNewWorkOrder = () => {
    naviagte("/work-orders/create/summary");
  };

  return (
    <div className="border-b border-zinc-300 bg-white h-[64px] ">
      <BaseWrapper>
        <div className="grid grid-cols-7 py-3 items-center">
          <div className="col-span-2">
            <h3 className="font-light">Work Order</h3>
          </div>
          {authRole !== ROLES.FREELANCE && (
            <div className="col-span-5 flex  items-center justify-end">
              <div>
                <BaseButton
                  variant="contained"
                  color="primary"
                  style={{
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                  onClick={handleNewWorkOrder}
                >
                  <p className="text-white">Create New Work Order</p>
                </BaseButton>
              </div>
            </div>
          )}
        </div>
      </BaseWrapper>
    </div>
  );
};

export default WorkOrderListingHeader;
