import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Qualification = ({ qualifications }) => {
  const formattedQualifications = qualifications?.reduce((acc, curr) => {
    const typeName = curr.qualificationType.name;
    if (!acc[typeName]) {
      acc[typeName] = [];
    }
    acc[typeName].push(curr);
    return acc;
  }, {});

  return (
    <BaseCard className="pt-4 pb-6 px-16  rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Work Order Eligibility</h6>
      </div>
      <div>
        {formattedQualifications &&
          Object?.keys(formattedQualifications)?.map((typeName, id) => (
            <div
              className={`pt-2 px-3 rounded-md flex  mb-2.5 pb-4 ${
                Object?.keys(formattedQualifications).length - 1 > id
                  ? "border-b"
                  : ""
              }`}
              key={id}
            >
              <p className="font-normal min-w-40">{typeName}:</p>

              <div className="flex flex-wrap gap-2">
                {formattedQualifications[typeName]?.map((subType, index) => (
                  <p
                    className="font-light bg-[#F6F6F6] rounded-sm  px-3 py-[1px]  w-fit"
                    key={index}
                  >
                    {subType.name}
                  </p>
                ))}
              </div>
            </div>
          ))}
      </div>
    </BaseCard>
  );
};

export default Qualification;
