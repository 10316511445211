import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import dayjs from "dayjs";

const Schedule = ({ schedule, allowedHours }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-16 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex  pb-1 justify-between">
        <h6 className="text-dark">Schedule</h6>
        <div className="flex items-center bg-[#F2FAFF] py-1 px-4 rounded-md mb-2">
          <i className="fa-solid fa-circle-exclamation text-primary"></i>
          <p className=" ml-3 font-normal">Fixed Start</p>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 mt-6">
          <p>Start at a specific date and time</p>
          <p className="font-normal">
            {dayjs(schedule.orderStartDate).format("dddd, DD/MM/YYYY")} at{" "}
            {dayjs(schedule.orderStartTime).format("hh:mm A")} (
            {schedule.timeZone})
          </p>
        </div>
        <div className="grid grid-cols-2  mt-4">
          <p>Approximate hours to complete</p>
          <p className="font-normal">{allowedHours || 0} hour(s)</p>
        </div>

        <p className="mt-8">
          Updated by <span className="text-primary">Colin Regan</span>
           on 8/12/2024 at 1:38 PM(EDT)
        </p>
      </div>
      <div className="border-t my-6"></div>

      {schedule.confirmedWorkOrderAt ? (
        <div>
          <div className="grid grid-cols-2 mt-6">
            <p>Confirmed Start Time</p>
            <p className="font-normal">
              {dayjs(schedule.confirmedWorkOrderAt).format("dddd, DD/MM/YYYY")}{" "}
              at {dayjs(schedule.confirmedWorkOrderAt).format("h:mm A")}
              {/* Monday, 8/19/2024 at 9:00 AM (EDT) */}
            </p>
            <p></p>
            <p className="font-normal">({schedule.timeZone})</p>
          </div>
          <div className="grid grid-cols-2  mt-4">
            <p>Duration</p>
            <p className="font-normal">3 hour(s)</p>
          </div>

          <p className="mt-8">
            Confirmed by <span className="text-primary">Ahmad Warsame</span>
             on 8/12/2024 at 1:38 PM(EDT)
          </p>
        </div>
      ) : (
        <p className="text-center font-normal italic">
          Ready to go has not been set
        </p>
      )}
    </BaseCard>
  );
};

export default Schedule;
