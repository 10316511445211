import axios from "../../lib/axiosInstance";

export function fetchQualificationTypes() {
  return axios.get(`qualifications`).then((res) => res.data);
}

export function fetchSubQualificationTypes(id) {
  return axios
    .get(`qualifications/subQualificationsByQualificationId/${id}`)
    .then((res) => res.data);
}
