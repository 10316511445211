import React from "react";
import dayjs from "dayjs";

const ScheduleCard = ({ schedule, assignedTo, status, tasks, timeLogs }) => {
  const checkIn = tasks?.find((task) => task.taskType === "CHECK_IN");
  const checkOut = tasks?.find((task) => task.taskType === "CHECK_OUT");

  const totalHours =
    timeLogs?.reduce((acc, timeLog) => acc + +timeLog.totalHours, 0) || null;

  return (
    <>
      {assignedTo && (status === "ASSIGNED" || status === "CONFIRM") ? (
        <ScheduleAssignedConfirmedCard
          title={schedule.confirmedWorkOrderAt ? "Ready to go" : "Not Ready"}
          scheduleType={schedule.scheduleType}
          startDate={schedule.confirmedWorkOrderAt}
          startTime={schedule.confirmedWorkOrderAt}
          timeZone={schedule.timeZone}
        />
      ) : status === "INPROGRESS" ? (
        <ScheduleInProgressCard
          title="On Track"
          startDateTime={checkIn?.completedTask.text}
          isCheckOut={checkOut.completedTask.isCompleted}
          endDateTime={checkOut.completedTask.text}
        />
      ) : status === "INCOMPLETE" ? (
        <ScheduleInCompleteCard title="Incomplete" totalHours={totalHours} />
      ) : (
        <SchedulePublishedCard
          title={
            schedule.scheduleType === "WORK_BETWEEN"
              ? "Work Between"
              : "Hard Time"
          }
          scheduleType={schedule.scheduleType}
          startDate={schedule?.orderStartDate}
          startTime={schedule?.orderStartTime}
          timeZone={schedule.timeZone}
        />
      )}
    </>
  );
};

export default ScheduleCard;

function SchedulePublishedCard({ title, startDate, startTime, timeZone }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-regular fa-clock text-primary text-3xl"></i>
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark">Schedule: {title}</p>
          <p className="mt-1 text-primary">
            {dayjs(startDate).format("DD/MM/YYYY")} 
            {dayjs(startTime).format("hh:mm A")} <br /> ({timeZone})
          </p>{" "}
        </div>
      </div>
    </div>
  );
}

function ScheduleInCompleteCard({ title, totalHours }) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-regular fa-clock text-primary text-3xl"></i>
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark">Status: {title}</p>
          <p className="mt-1 text-primary">Working Hours:</p>
          <p className="text-primary">{totalHours?.toFixed(2)} Hours</p>{" "}
        </div>
      </div>
    </div>
  );
}

function ScheduleAssignedConfirmedCard({
  title,
  startDate,
  startTime,
  timeZone,
}) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>
        {/* <i className="fa-regular fa-clock text-primary text-3xl"></i> */}
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark text-sm">Status: {title}</p>
          {startDate ? (
            <p className=" mt-1 text-primary">
              Ready to go marked at:
              <br />
              {dayjs(startDate).format("DD/MM/YYYY")} 
              {dayjs(startTime).format("hh:mm A")} <br /> ({timeZone})
            </p>
          ) : (
            <p className=" mt-1 text-primary">
              Ready to go has not been marked yet
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function ScheduleInProgressCard({
  title,
  startDateTime,
  isCheckOut,
  endDateTime,
}) {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-solid fa-circle-info text-primary text-3xl"></i>
        {/* <i className="fa-regular fa-clock text-primary text-3xl"></i> */}
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark text-sm">Status: {title}</p>
          {isCheckOut ? (
            <p className="text-primary mt-1">
              Checked out at: <br />
              <span className="text-primary">
                {dayjs(endDateTime).format("DD/MM/YYYY")} {""}
              </span>
              <span className="text-primary">
                {dayjs(endDateTime).format("hh:mm A")}{" "}
              </span>
            </p>
          ) : (
            <p className="text-primary mt-1">
              Checked in at: <br />
              <span className="text-primary">
                {dayjs(startDateTime).format("DD/MM/YYYY")} {""}
              </span>
              <span className="text-primary">
                {dayjs(startDateTime).format("hh:mm A")}{" "}
              </span>
            </p>
          )}{" "}
        </div>
      </div>
    </div>
  );
}
