import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import {
  brandFontFamily,
  brandFontSize,
  brandInputTextColor,
} from "../../../utils/variables";
import { FormControl, InputLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const CustomInputLabel = styled(InputLabel)(({ theme, label }) => ({
  color: brandInputTextColor,
  fontSize: brandFontSize, // Customize the label size
  fontWeight: 400,
  fontFamily: `${brandFontFamily} !important`,
  marginBottom: label ? "6px" : "",
}));

const CustomTextField = styled(TextField)(({ isDefaultField }) => ({
  "& .MuiInputLabel-root": {
    fontSize: brandFontSize,
    fontWeight: 400,
    fontFamily: brandFontFamily,
  },
  "& .MuiInputBase-root": {
    color: brandInputTextColor,
    fontFamily: brandFontFamily,

    borderRadius: "4px",
    padding: "0px",
    width: "100%",
    fontSize: brandFontSize,
    fontWeight: 300,
    height: "40px",
    backgroundColor: "#FFF",
  },
  "& .MuiInputLabel-outlined": {
    backgroundColor: "#fff", // Optional: to ensure background color doesn't affect the label
    padding: "0 4px", // Ensures padding from both left and right
  },
  "& .MuiInputBase-input": {
    padding: "13px",
    fontSize: brandFontSize,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    ...(!isDefaultField && {
      border: "none !important",
      boxShadow: "1px 1px 4px 1px #00000021",
    }),
  },
}));

const BaseTextField = ({
  labelShrink = true,
  label,
  className,
  value,
  onChange,
  optional = true,
  fullHeight = true,
  variant = "outlined",
  hint,
  bottomRightMsg,
  error,
  errorMsg,
  isDefaultField = true,
  startAdornment, // Pass startAdornment as a prop
  endAdornment, // Pass endAdornment as a prop
  ...props
}) => {
  return (
    <div>
      {!labelShrink && (
        <CustomInputLabel shrink={labelShrink} label>
          {label}
          {optional && <span className="font-light italic"> - Optional</span>}
          {hint && (
            <span className="text-xs ml-2 font-light text-right">{hint}</span>
          )}
        </CustomInputLabel>
      )}
      <CustomTextField
        InputLabelProps={{
          shrink: labelShrink,
        }}
        value={value}
        onChange={onChange}
        label={labelShrink ? label : null}
        variant={variant}
        isDefaultField={isDefaultField}
        InputProps={{
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment style={{ marginRight: "14px" }} position="end">
              {endAdornment}
            </InputAdornment>
          ),
        }}
        {...props}
      />
      <div className="flex justify-between mt-1.5">
        {error && <p className="text-red-500 w-full">{errorMsg}</p>}
        {bottomRightMsg && (
          <p className="text-right  w-full">{bottomRightMsg}</p>
        )}
      </div>
    </div>
  );
};

export default BaseTextField;
