import * as React from "react";
import Modal from "@mui/material/Modal";
import BaseCard from "../BaseCard";
import { Box } from "@mui/material";
import "./style.css";

const defaultStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "800px",
};

export default function BaseModal({ children, open, style, close }) {
  const handleClose = () => close();

  return (
    <div>
      <Modal
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <BaseCard
            style={{
              ...defaultStyle,
              ...style,
            }}
          >
            {children}
          </BaseCard>
        </Box>
      </Modal>
    </div>
  );
}
