import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const BackgroundVerification = () => {
  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Background Verification</h6>
      </div>
      <BaseCard className="shadow-md mb-4">
        <div className="flex items-center justify-between py-2 px-4">
          <p>Employment</p>
          <i className="fa-solid fa-circle-check text-secondary"></i>
        </div>
      </BaseCard>
      <BaseCard className="shadow-md mb-4">
        <div className="flex items-center justify-between py-2 px-4">
          <p>Employment</p>
          <i className="fa-solid fa-circle-xmark text-[#D80000]"></i>
        </div>
      </BaseCard>
      <BaseCard className="shadow-md">
        <div className="flex items-center justify-between py-2 px-4">
          <p>Employment</p>
          <i className="fa-solid fa-circle-check text-secondary"></i>
        </div>
      </BaseCard>
    </BaseCard>
  );
};

export default BackgroundVerification;
