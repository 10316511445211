import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const HistoryTab = () => {
  return (
    <BaseCard className="pt-4 pb-72 px-16 mt-1 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">History</h6>
      </div>
      <div className="grid grid-cols-2 gap-x-12">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((element) => (
          <div className="flex justify-between items-center bg-[#F6F9FF] p-4 rounded-lg mb-5">
            <div>
              <p className="font-normal">Substatus changed to Requested</p>
              <p className="mt-1 text-xs">8/29/2024 at 11:50:30 PM(EDT)</p>
            </div>
            <div className="flex items-center justify-between w-64">
              <div>
                <p className="font-normal">By User</p>
                <p className="text-xs mt-1 text-primary">Istkhar hussain</p>
              </div>
              <i class="fa-solid fa-arrow-right text-3xl"></i>
              <div>
                <p className="font-normal">To User</p>
                <p className="text-xs mt-1 text-primary">David Casey</p>
              </div>
            </div>

            <div className="bg-white rounded-md flex items-center h-10 w-10 justify-center cursor-pointer">
              <i class="fa-solid fa-align-right "></i>
            </div>
          </div>
        ))}
      </div>
    </BaseCard>
  );
};

export default HistoryTab;
