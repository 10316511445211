import React from "react";
import { SidebarItem } from "./SidebarItem";
import { ReactComponent as BrandLogo } from "../../assets/sidebar-nav/brand-logo.svg";
import { Link } from "react-router-dom";
import "./style.css";
import useAuth from "../../hooks/useAuth";

const SidebarNav = () => {
  const { authRoutes } = useAuth();
  return (
    <div className="w-24 h-screen fixed left-0 top-0 border-r border-zinc-300 z-20 bg-white">
      <Link style={{ textDecoration: "none" }}>
        <div className="flex justify-center mt-1 text-gray-950 border-b border-zinc-300 pb-[16px] pt-[14px] mb-3 text-sm">
          <BrandLogo />
        </div>
      </Link>

      <div className="px-2">
        {authRoutes.map((route, index) => (
          <SidebarItem
            isShowClient={route.isShowClient}
            isShow={route.isShow}
            key={route.path + index}
            path={route.path}
            icon={route.icon}
            activeIcon={route.activeIcon}
            text={route.text}
            childs={route.childs}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarNav;
