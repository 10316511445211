import React from "react";
import PayForm from "../Forms/PayForm";
import CustomFieldsForm from "../Forms/CustomFieldsForm";
import TasksForm from "../Forms/TasksForm";

const WorkPay = () => {
  return (
    <div>
      <PayForm />
      {/* <CustomFieldsForm /> */}
      <TasksForm />
    </div>
  );
};

export default WorkPay;
