import React from "react";

const WorkingHoursCard = ({ totalHours }) => {
  return (
    <div className="shadow-md rounded-md py-3 px-4 h-full min-h-24  w-full">
      <div className="flex">
        <i className="fa-regular fa-clock text-primary text-3xl"></i>
        <div className="ml-2 mt-2">
          <p className="font-normal text-dark text-sm">Working Hours</p>
          <p className="text-sm mt-1 text-primary">{totalHours} Hours</p>
        </div>
      </div>
    </div>
  );
};

export default WorkingHoursCard;
