import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import "./style.css";

const containerStyle = {
  width: "100%",
  height: "270px",
  borderRadius: "10px",
};

const options = {
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};
function Map({ latitude, longitude }) {
  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        options={options}
      >
        <MarkerF position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(Map);
