import axios from "../../lib/axiosInstance";

export function checkIn(payload) {
  return axios.post(`timeLogs/checkIn`, payload).then((res) => res.data);
}

export function checkOut(payload) {
  return axios.post(`timeLogs/checkOut`, payload).then((res) => res.data);
}

export function createTimeLog(payload) {
  return axios.post(`timeLogs`, payload).then((res) => res.data);
}

export function fetchWorkOrderTimeLogs(id) {
  return axios.get(`timeLogs/${id}`).then((res) => res.data);
}
