import React from "react";

const WorkOrderStatus = ({ status, textSize = "text-xs", width = "w-20" }) => {
  const getStatus = () => {
    if (status === "PUBLISHED") {
      return (
        <p
          className={`text-white ${width} bg-[#B6862A] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Published
        </p>
      );
    } else if (status === "DRAFT") {
      return (
        <p
          className={`text-white ${width} bg-[#C0B023] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Drafted
        </p>
      );
    } else if (status === "REQUESTED") {
      return (
        <p
          className={`text-white ${width} bg-purple-300 text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Requested
        </p>
      );
    } else if (status === "ROUTED") {
      return (
        <p
          className={`text-[#328294] ${width} bg-[#B6EDFA] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Routed
        </p>
      );
    } else if (status === "COUNTER") {
      return (
        <p
          className={`text-[#FF5F00] ${width} bg-[#FFEBDF] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Counter
        </p>
      );
    } else if (status === "DECLINED") {
      return (
        <p
          className={`text-[#933434] ${width} bg-[#F9B8B8] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Decline
        </p>
      );
    } else if (status === "ASSIGNED") {
      return (
        <p
          className={`text-white ${width} bg-[#2561A9] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Assigned
        </p>
      );
    } else if (status === "CONFIRM") {
      return (
        <p
          className={`text-white ${width} bg-[#5D7464] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Confirmed
        </p>
      );
    } else if (status === "INPROGRESS") {
      return (
        <p
          className={`text-white ${width} bg-[#092C28] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          In Progress
        </p>
      );
    } else if (status === "WORKDONE") {
      return (
        <p
          className={`text-white ${width} bg-[#295E80] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Work Done
        </p>
      );
    } else if (status === "INCOMPLETE") {
      return (
        <p
          className={`text-white ${width} bg-[#EF5900] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Incomplete
        </p>
      );
    } else if (status === "APPROVED") {
      return (
        <p
          className={`text-white ${width} bg-[#0F40AE] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Approved
        </p>
      );
    } else if (status === "PAID") {
      return (
        <p
          className={`text-white ${width} bg-[#1D913E] text-center py-[4px] rounded-[5px] ${textSize}`}
        >
          Paid
        </p>
      );
    }
  };
  return getStatus();
};

export default WorkOrderStatus;
