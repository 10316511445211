import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Sum = ({ payment, status }) => {
  return (
    <BaseCard className="pt-4 pb-6 px-56 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex justify-between pb-3">
        <h6 className="text-dark">Sum</h6>
      </div>

      <section>
        <BaseCard className="mt-2 p-4 shadow-md" elevation={0}>
          <div className="flex items-center mb-2">
            <p className="min-w-40">Work Order Cost</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">
              ${payment?.totalWorkOrderCost?.toFixed(2)}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <p className="min-w-40">Service fee</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">
              ${payment?.serviceFee?.toFixed(2)}
            </p>
          </div>
          <div className="flex items-center mb-2">
            <p className="min-w-40">Tax%</p>
            <div className="border-t border-dotted border-gray-500  w-full"></div>
            <p className="min-w-14 ml-5 font-normal">$0</p>
          </div>
        </BaseCard>
        <div className="flex items-center mt-6 justify-between bg-primary px-3 py-1 rounded-md">
          {status === "WORKDONE" ||
          status === "APPROVED" ||
          status === "PAID" ? (
            <h6 className="font-normal text-white">Grand Total</h6>
          ) : (
            <h6 className="font-normal text-white">Total Estimate</h6>
          )}

          <h6 className="font-normal text-white">
            ${payment?.grandTotal?.toFixed(2)}
          </h6>
        </div>

        <div className="mt-2 justify-between bg-[#EDEDED] px-3 py-1 rounded-md">
          <p>
            <span className="font-normal">Please note: </span>The above
            calculations are provided to give you an estimated idea of the work
            order cost. The actual cost will be determined upon completion of
            the work order, based on the actual time spent.
          </p>
        </div>
      </section>
    </BaseCard>
  );
};

export default Sum;
