import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function BaseCheckbox({ label, checked, ...props }) {
  return (
    <FormControlLabel
      style={{ margin: 0 }}
      className="h-8"
      control={
        <Checkbox
          checkedIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 17 18"
              fill="none"
            >
              <rect
                y="0.0576477"
                width="17"
                height="17.318"
                rx="3"
                fill="#FF5F00"
              />
              <path
                d="M4 7.90169L6.1 11.2634C7.2 9.83724 10.12 6.61812 13 5.15118"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          }
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 17 18"
              fill="none"
            >
              <rect
                x="0.5"
                y="1.15628"
                width="16"
                height="16.318"
                rx="2.5"
                stroke="#4D4D4D"
              />
            </svg>
          }
          checked={checked}
          className="text-primary"
          {...props}
        />
      }
      label={label && <p className="font-light">{label}</p>}
    />
  );
}
