import React, { useState } from "react";
import BaseCard from "../../../components/ui/BaseCard";
import BaseSelect from "../../../components/ui/BaseSelect";
import { ReactComponent as PlusIcon } from "../../../assets/global-icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/global-icons/remove-icon.svg";
import BaseSwitch from "../../../components/ui/BaseSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  addQualification,
  removeQualification,
  updateQualification,
  updateSubQualificationOptions,
} from "../../../store/workOrder/workOrderSlice";
import { useQuery } from "@tanstack/react-query";
import {
  fetchQualificationTypes,
  fetchSubQualificationTypes,
} from "../../../services/api/qualification";

const QualificationForm = () => {
  const dispatch = useDispatch();

  const qualifications = useSelector(
    (state) => state.workOrder.scheduling.qualifications
  );

  console.log("qualifications", qualifications);

  const [isWorkQualification, setIsWorkQualification] = useState(true);
  const [listOfQualifications, setListOfQualifications] = useState([]);

  const subQualificationOptions = useSelector(
    (state) => state.workOrder.subQualificationOptions
  );

  const {
    isLoading: IsqualificationsTypesLoading,
    error: isqualificationsTypesError,
    data: qualificationsTypesData,
  } = useQuery({
    queryKey: ["qualificationsTypes"],
    queryFn: fetchQualificationTypes,
  });

  const {
    isLoading: IssubQualificationsDataLoading,
    error: issubQualificationsDataError,
    data: subQualificationsData,
  } = useQuery({
    queryKey: ["subQualifications", qualifications.qualificationTypeId], // Add qualificationTypeId to the queryKey
    queryFn: () =>
      fetchSubQualificationTypes(qualifications.qualificationTypeId),
    enabled: false,
  });

  const formattedQualificationTypes = qualificationsTypesData?.data?.map(
    (qualificationType) => ({
      label: qualificationType?.name,
      value: qualificationType?.id,
    })
  );

  const handleAddQualification = () => {
    dispatch(addQualification());
  };

  const handleChange = async (index, event) => {
    const { name, value } = event.target;
    // If the changed field is qualificationTypeId, fetch sub-qualifications
    if (name === "qualificationTypeId") {
      const fetchedSubQualifications = await fetchSubQualificationTypes(value);

      // Update sub-qualification options for the selected qualificationTypeId
      dispatch(
        updateSubQualificationOptions({
          qualificationTypeId: value,
          options: fetchedSubQualifications.data.map((subQualification) => ({
            label: subQualification.name,
            value: subQualification.id,
          })),
        })
      );
    }
    dispatch(updateQualification({ index, field: name, value }));
  };

  const removeItem = (id) => {
    dispatch(removeQualification(id));
  };

  return (
    <BaseCard className="py-4 px-16 mt-2 rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center">
        <h6 className="mb-2 text-dark">
          Work Order Eligibility{" "}
          <span className="text-base italic text-[#686868]">(Optional)</span>
        </h6>
        {/* <p>(Optional)</p> */}
        <BaseSwitch
          className="ml-4 mb-2"
          secondary={true}
          checked={isWorkQualification}
          onChange={() => setIsWorkQualification(!isWorkQualification)}
        />
      </div>

      {isWorkQualification && (
        <div className="grid grid-cols-2">
          <div className="col-span-2">
            <p className="leading-normal mb-4">
              Qualifications may encompass various elements such as licenses,
              certifications, insurance, equipment, screenings, and specific
              selection criteria. If any qualifications are absent from a
              requesting provider's profile, you will be prompted to confirm the
              assignment.
            </p>
          </div>

          {qualifications.map((qualification, index) => (
            <div
              key={qualification.id}
              className="col-span-2 grid grid-cols-2 gap-6 px-5 py-2 rounded-lg mb-4"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              <div className="col-span-2 flex items-center">
                <div
                  className="mr-5 mt-3 cursor-pointer"
                  onClick={() => removeItem(qualification.id)}
                >
                  <RemoveIcon />
                </div>
                <div className="w-full mr-10">
                  <BaseSelect
                    className="mb-3"
                    fullHeight={false}
                    disabled={qualification.qualificationTypeId ? true : false}
                    required={true}
                    labelShrink={false}
                    label="Qualification Type"
                    options={formattedQualificationTypes}
                    name="qualificationTypeId"
                    isDefaultField={false}
                    value={qualification.qualificationTypeId}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
                <div className="w-full">
                  <BaseSelect
                    className="mb-3"
                    labelShrink={false}
                    label={
                      formattedQualificationTypes?.find(
                        (type) =>
                          type.value === qualification?.qualificationTypeId
                      )?.label || "License"
                    }
                    fullHeight={false}
                    placeholder="Select..."
                    isDefaultField={false}
                    required={true}
                    options={
                      subQualificationOptions[
                        qualification.qualificationTypeId
                      ] || []
                    } // Use the dynamically fetched options
                    name="qualificationSubTypes"
                    multiple
                    value={qualification.qualificationSubTypes}
                    onChange={(e) => handleChange(index, e)}
                  />
                </div>
              </div>
            </div>
          ))}
          <div
            className="flex items-center mt-2 cursor-pointer col-span-2"
            onClick={handleAddQualification}
          >
            <PlusIcon />

            <p className="text-secondary ml-2"> Add Qualification</p>
          </div>
        </div>
      )}
    </BaseCard>
  );
};

export default QualificationForm;
