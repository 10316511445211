import { Avatar } from "@mui/material";
import React from "react";
import BaseButton from "../../components/ui/BaseButton";

const UserDetailsCard = ({
  authRole,
  createdBy,
  workOrderManager,
  offersCount,
  assignedTo,
}) => {
  return (
    <div className="shadow-md  rounded-md py-3 px-4 h-full min-h-24 flex flex-col justify-between w-full">
      <div className="flex ">
        <Avatar
          src="https://img.freepik.com/free-photo/front-view-smiley-business-man_23-2148479583.jpg?t=st=1725259431~exp=1725263031~hmac=698528c51f80409024274dce1d7606f1b669320d2d11a09e75f7fa4f32a81b56&w=1380"
          sx={{ width: 36, height: 36 }}
        />
        {authRole === "FREELANCER" && (
          <div className="ml-2">
            <p className="font-normal text-dark text-sm">
              Manager/Site contact
            </p>

            <p className="text-sm text-primary capitalize">
              {workOrderManager
                ? workOrderManager?.firstName + " " + workOrderManager?.lastName
                : createdBy?.firstName + " " + createdBy?.lastName}
            </p>
          </div>
        )}

        {authRole === "CLIENT" && (
          <div className="ml-2">
            {assignedTo ? (
              <div>
                <p className="text-dark font-normal text-sm">
                  Service Provider
                </p>
                <p className="text-primary">
                  {assignedTo.firstName + " " + assignedTo.lastName}
                  <span className="ml-1 text-[#686868] text-sm">
                    ID: {assignedTo.id}
                  </span>
                </p>
                <p className="text-primary">{assignedTo.phoneNumber}</p>
              </div>
            ) : (
              <div>
                <p className="font-normal text-dark text-sm">
                  Provider: unassigned
                </p>
              </div>
            )}
            <div>
              {!assignedTo && (
                <p className=" text-primary capitalize">
                  {offersCount?.requested === 0 &&
                    offersCount?.counter === 0 &&
                    offersCount?.routed === 0 &&
                    "No Request"}
                  {offersCount?.requested !== 0 &&
                    offersCount?.requested + " Requests, "}
                  {offersCount?.counter !== 0 &&
                    offersCount?.counter + " Counters, "}
                  {offersCount?.routed !== 0 && offersCount?.routed + " Routed"}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {/*   {authRole === "CLIENT" && (
        <div className="self-end">
          {assignedTo && (
            <BaseButton
              className="pt-1 pb-1 bg-white text-primary pl-2 pr-2 text-xs "
              fullWidth={false}
            >
              Leave Feedback
            </BaseButton>
          )}
        </div>
      )} */}
    </div>
  );
};

export default UserDetailsCard;
