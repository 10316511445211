const CustomTable = ({ columns, data, currentFilter }) => {
  const requestedOffers = data.filter(
    (item) => !item.isCounter && item.status !== "ROUTED"
  );
  const counterOffers = data.filter(
    (item) => item.isCounter && item.status !== "ROUTED"
  );

  const routedOffers = data.filter(
    (item) => !item.isCounter && item.status === "ROUTED"
  );

  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        {/* <div className="min-w-full "> */}
        <table
          className="min-w-max  border-collapse w-full"
          style={{ tableLayout: "auto !important" }}
        >
          <div>
            <thead className="table !important w-full">
              <tr>
                {columns.map((column, index) => (
                  <>
                    {(currentFilter === "DECLINED" ||
                      currentFilter === "REJECTED") &&
                    column.label === "Action" ? (
                      <th></th>
                    ) : (
                      <th
                        key={index}
                        className={`text-sm px-3 py-2 border-r border-[#8C8C8C] last:border-r-0 text-left font-normal text-[#686868] overflow-scroll ${
                          column.width ? `w-[${column.width}]` : "w-[200px]"
                        }`}
                        style={{
                          borderBottomWidth: "1px",
                          borderBottomColor: "#DEDEDE",
                        }}
                      >
                        {column.label}
                      </th>
                    )}
                  </>
                ))}
              </tr>
            </thead>
            {requestedOffers.length > 0 && (
              <p className="font-normal bg-[#EBF1FF] border-y p-1">
                REQUESTED:{" "}
                <span className="font-light">
                  will be immediately assigned.
                </span>
              </p>
            )}
            <tbody className="table w-full">
              {requestedOffers.map((item, index) => (
                <tr key={item.id} className="border-b last:border-b-0">
                  {columns.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`px-2 py-1  ${
                        column.width ? `w-[${column.width}]` : "w-[200px]"
                      }`}
                    >
                      {column.renderCell(item)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {counterOffers.length > 0 && (
              <p className="font-normal bg-[#EBF1FF] border-y p-1">
                COUNTER OFFERS:{" "}
                <span className="font-light">
                  will be immediately assigned after your approval.
                </span>
              </p>
            )}
            <tbody className="table w-full">
              {counterOffers.map((item, index) => (
                <tr key={item.id} className="border-b last:border-b-0">
                  {columns.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`px-2 py-1  ${
                        column.width ? `w-[${column.width}]` : "w-[200px]"
                      }`}
                    >
                      {column.renderCell(item)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {routedOffers.length > 0 && (
              <p className="font-normal bg-[#EBF1FF] border-y p-1">
                ROUTED OFFERS:{" "}
                <span className="font-light">
                  will be immediately assigned to one who accept the offer.
                </span>
              </p>
            )}
            <tbody className="table w-full">
              {routedOffers.map((item, index) => (
                <tr key={item.id} className="border-b last:border-b-0">
                  {columns.map((column, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={`px-2 py-1  ${
                        column.width ? `w-[${column.width}]` : "w-[200px]"
                      }`}
                    >
                      {column.renderCell(item)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
    </div>
  );
};

export default CustomTable;
