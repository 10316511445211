import React from "react";

const ProfileDetailsHeader = ({ user }) => {
  const openGooglMapLocation = () => {
    window.open(
      "https://maps.google.com?q=" +
        user?.address?.latitude +
        "," +
        user?.address?.longitude
    );
  };

  console.log(user);
  return (
    <div className="flex items-center col-span-2 ">
      <div
        className="rounded-xl h-[100px] w-[100px] bg-center	bg-cover
         bg-[url('https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=1380&t=st=1725609043~exp=1725609643~hmac=5eb115f7cb7ecce9ec345d82f11cb71356dc34ecafe344f4ff87aa0251e77f1a')]"
      />
      <div className="ml-5">
        <h6 className="font-normal capitalize">
          {user?.firstName} {user?.lastName}{" "}
          <span className="text-base">ID: {user?.id}</span>
        </h6>
        <div className="flex mt-3">
          {user?.address?.addressLine1 && (
            <div
              className="flex bg-[#E7FFF4] py-1 px-3 rounded-md mr-6 cursor-pointer"
              onClick={openGooglMapLocation}
            >
              <i className="fa-solid fa-location-dot text-[#147648] text-sm"></i>
              <p className="text-[#147648] text-sm ml-2">
                {user?.address.addressLine1}
              </p>
            </div>
          )}
          <div className="flex bg-[#F1FDFF] py-1 px-3 rounded-md mr-6">
            <i className="fa-solid fa-clock-rotate-left text-[#35BDCF] text-sm"></i>
            <p className="text-[#35BDCF] text-sm ml-2">Job History</p>
          </div>
          <div className="flex bg-[#ECF4FF] py-1 px-3 rounded-md mr-6">
            <i className="fa-solid fa-file-invoice text-[#3573CF] text-sm"></i>
            <p className="text-[#3573CF] text-sm ml-2">611 Jobs Completed</p>
          </div>
          <div className="flex bg-[#F8FFED] py-1 px-3 rounded-md">
            <i className="fa-solid fa-suitcase text-[#74A525] text-sm"></i>
            <p className="text-[#74A525] text-sm ml-2">30 Clients</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsHeader;
