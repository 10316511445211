import React from "react";
import BaseCard from "../../../components/ui/BaseCard";

const Pay = ({ payment, paymentType }) => {
  return (
    <>
      <BaseCard className="pt-4 pb-3 px-56 mt-1 rounded-lg" elevation={1}>
        <div className="border-bottom mb-2 flex items-center pb-1">
          <h6 className="text-dark">Payment</h6>
          <span className="text-xs ml-3 text-[#686868]">
            {paymentType === "HOURLY" ? "Hourly" : "Blended"}
          </span>
        </div>
        {/* <BaseCard className="mt-6 p-4 shadow-md"> */}
        <p className="text-sm font-normal">
          Payment Terms
          <span className="text-secondary text-lg ml-1">
            {payment?.paymentTerms}
          </span>
        </p>

        <p>
          Payment for this work order will be processed 15 days after the job is
          completed. If there are any issues related to the work, you will be
          notified within 7 days.
        </p>
      </BaseCard>
    </>
  );
};

export default Pay;
