import React from "react";
import Pay from "../TabsCard/Pay";
import Sum from "../TabsCard/Sum";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchWorkOrderPaymetDetails } from "../../../services/api/workOrder";
import { useParams } from "react-router-dom";
import Labour from "../TabsCard/Labour";
import ServiceFee from "../TabsCard/ServiceFee";
import TimeLogs from "../TabsCard/TimeLogs";
import SmartAudit from "../TabsCard/SmartAudit";
import Tax from "../TabsCard/Tax";

const PayTab = () => {
  const { id } = useParams();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const {
    isLoading,
    error,
    data: workOrderPaymentData,
  } = useQuery({
    queryKey: ["workOrderPayment"],
    queryFn: () => fetchWorkOrderPaymetDetails(id),
    enabled: !!id,
  });

  const payment = workOrderPaymentData?.data;
  console.log("workOrderDetail", workOrderDetail);

  return (
    <div className="grid grid-cols-1 gap-2">
      <Pay payment={payment} paymentType={workOrderDetail?.paymentType} />

      <Labour
        payment={payment}
        paymentType={workOrderDetail?.paymentType}
        status={workOrderDetail?.status}
      />
      <ServiceFee payment={payment} />
      <Tax payment={payment} />
      <Sum payment={payment} status={workOrderDetail?.status} />
      <TimeLogs />
      <SmartAudit />
      {/*  <div className="grid grid-cols-2 gap-2">
          <div>
            
          </div>
        </div> */}

      {/* <div className="col-span-2">
        
      </div> */}
      {/* <div className="col-span-2">
        
      </div> */}
    </div>
  );
};

export default PayTab;
