import React from "react";
import BaseCard from "../../../components/ui/BaseCard";
import { useMutation } from "@tanstack/react-query";
import { updateTask } from "../../../services/api/task";
import { useSelector, useDispatch } from "react-redux";
import { setWorkOrderDetail } from "../../../store/workOrder/workOrderSlice";
import { showToast } from "../../../store/toast/toastSlice";
import useAuth from "../../../hooks/useAuth";
import { checkIn, checkOut } from "../../../services/api/timeLog";
import dayjs from "dayjs";
import { useUpdateWorkOrder } from "../../../hooks/useUpdateWorkOrder";

const Tasks = ({ tasks, assignedTo }) => {
  const dispatch = useDispatch();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const { authRole } = useAuth();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const {
    isLoading: isCheckInLoading,
    error: isCheckInError,
    data: timeLogData,
    mutateAsync: checkInMutation,
  } = useMutation({
    mutationFn: checkIn,
    enabled: false,
  });
  const {
    isLoading: isCheckOutLoading,
    error: isCheckOutError,
    data: checkOutTimeLogData,
    mutateAsync: checkOutMutation,
  } = useMutation({
    mutationFn: checkOut,
    enabled: false,
  });

  const {
    isLoading: isTaskUpdateLoading,
    error: isTaskUpdateError,
    data: updatedTaskData,
    mutateAsync: updateTaskMutation,
  } = useMutation({
    mutationFn: updateTask,
    enabled: false,
  });

  const separateTasks = (tasks) => {
    const steps = {
      Prep: [],
      "On Site": [],
      Post: [],
    };

    tasks?.forEach((task) => {
      switch (task.taskType) {
        case "START_TIME":
          steps.Prep.push(task);
          break;
        case "CLOSE_NOTES":
          steps.Post.push(task);
          break;
        default:
          steps["On Site"].push(task);

          break;
      }
    });

    return steps;
  };

  const formattedTasks = separateTasks(tasks);

  const updateTaskApi = async (id, isCompleted) => {
    if (!assignedTo) {
      dispatch(
        showToast({
          message: "Work Order is not assigned to you.",
          type: "error",
        })
      );
      return;
    }
    try {
      let payload = {
        id,
        isCompleted,
      };

      const updatedTask = await updateTaskMutation(payload);

      updateWorkOrder();
    } catch (error) {
      dispatch(
        showToast({
          message: error.response.data.message,
          type: "error",
        })
      );
    }
  };

  const checkInApi = async (id) => {
    if (!assignedTo) {
      dispatch(
        showToast({
          message: "Work Order is not assigned to you.",
          type: "error",
        })
      );
      return;
    }
    try {
      // Get the user's location
      const location = await handleGetLocation();
      // Create the payload with correct latitude and longitude
      let payload = {
        checkInLatitude: location?.latitude,
        checkInLongitude: location?.longitude, // Corrected key
        workOrderId: workOrderDetail.id,
        taskId: id,
      };

      // Update the task and check-in
      // await updateTaskApi(id, isCompleted);
      const response = await checkInMutation(payload);

      // After successful task update and check-in, update the state
      updateWorkOrder();
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle the error if necessary
    }
  };

  const checkOutApi = async (id) => {
    if (!assignedTo) {
      dispatch(
        showToast({
          message: "Work Order is not assigned to you.",
          type: "error",
        })
      );
      return;
    }
    try {
      // Get the user's location
      const location = await handleGetLocation();
      // Create the payload with correct latitude and longitude
      let payload = {
        checkOutLatitude: location?.latitude,
        checkOutLongitude: location?.longitude,
        workOrderId: workOrderDetail.id,
        taskId: id,
      };

      // Update the task and check-in
      // await updateTaskApi(id, isCompleted);
      const response = await checkOutMutation(payload);

      // After successful task update and check-in, update the state
      // updateWorkOrderInStore(response, "WORKDONE");
      updateWorkOrder();
    } catch (error) {
      console.error("Error during check-in:", error);
      // Handle the error if necessary
    }
  };

  const updateWorkOrderInStore = (updatedTask, status) => {
    const updatedTasks = workOrderDetail.tasks.map((task) => {
      if (task.completedTask.id === updatedTask.id) {
        return {
          ...task,
          completedTask: updatedTask,
        };
      }
      return task;
    });

    dispatch(
      setWorkOrderDetail({
        ...workOrderDetail,
        status,
        tasks: updatedTasks,
      })
    );
  };

  const handleGetLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Resolve the promise with the position
            resolve(position.coords);
          },
          (error) => {
            // Reject the promise with an error
            switch (error.code) {
              case error.PERMISSION_DENIED:
                dispatch(
                  showToast({
                    message: "User denied the request for Geolocation.",
                    type: "error",
                  })
                );
                reject(new Error("User denied the request for Geolocation."));
                break;
              case error.POSITION_UNAVAILABLE:
                dispatch(
                  showToast({
                    message: "Location information is unavailable.",
                    type: "error",
                  })
                );
                reject(new Error("Location information is unavailable."));
                break;
              case error.TIMEOUT:
                dispatch(
                  showToast({
                    message: "The request to get user location timed out.",
                    type: "error",
                  })
                );
                reject(
                  new Error("The request to get user location timed out.")
                );
                break;
              case error.UNKNOWN_ERROR:
                dispatch(
                  showToast({
                    message: "An unknown error occurred.",
                    type: "error",
                  })
                );
                reject(new Error("An unknown error occurred."));
                break;
              default:
                dispatch(
                  showToast({
                    message: "An unknown error occurred.",
                    type: "error",
                  })
                );
                reject(new Error("An unknown error occurred."));
            }
          }
        );
      } else {
        // If the browser doesn't support Geolocation
        dispatch(
          showToast({
            message: "Geolocation is not supported by this browser.",
            type: "error",
          })
        );
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  return (
    <BaseCard className="pt-4 pb-6 px-16  rounded-lg" elevation={1}>
      <div className="border-bottom mb-4 flex items-center pb-1">
        <h6 className="text-dark">Tasks</h6>
        <i className="fa-solid fa-pencil ml-3"></i>
      </div>
      <div>
        <div>
          <p>Prep</p>

          {formattedTasks.Prep.map((task, index) => (
            <div
              className=" bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2"
              key={index}
            >
              <div className="flex items-center">
                <i
                  disabled={true}
                  className={
                    "cursor-pointer " +
                    `${
                      task?.completedTask?.isCompleted
                        ? "fa-solid fa-circle-check text-green-500"
                        : "fa-regular fa-circle"
                    }`
                  }
                  onClick={() => {
                    if (
                      authRole === "CLIENT" ||
                      task?.completedTask?.isCompleted
                    )
                      return;
                    updateTaskApi(
                      task?.completedTask.id,
                      !task?.completedTask?.isCompleted
                    );
                  }}
                ></i>
                <p className="ml-3">Ready to go</p>
              </div>
              {task.completedTask?.completedBy && (
                <p className="px-7 my-1 text-sm">
                  Completed by{" "}
                  {`${task.completedTask?.completedBy.firstName} ${
                    task.completedTask?.completedBy.lastName
                  } on ${dayjs(task.completedTask.text).format(
                    "MM/DD/YYYY"
                  )} at ${dayjs(task.completedTask.text).format("hh:mm A")}`}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="mt-5">
          <p>On Site</p>
          {formattedTasks["On Site"]?.map((task) => (
            <div key={task?.id}>
              {task.taskType === "CHECK_IN" ? (
                <div className=" bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2.5">
                  <div className="flex items-center">
                    <i
                      className={
                        "cursor-pointer " +
                        `${
                          task?.completedTask?.isCompleted
                            ? "fa-solid fa-circle-check text-green-500"
                            : "fa-regular fa-circle"
                        }`
                      }
                      onClick={() => {
                        if (
                          authRole === "CLIENT" ||
                          task?.completedTask?.isCompleted
                        )
                          return;
                        checkInApi(task.completedTask?.id);
                      }}
                    ></i>

                    <p className="ml-3">Check in</p>
                  </div>
                  {task.completedTask?.completedBy && (
                    <p className="px-7 my-1 text-sm border-t pt-3">
                      Completed by{" "}
                      {`${task.completedTask?.completedBy.firstName} ${
                        task.completedTask?.completedBy.lastName
                      } on ${dayjs(task.completedTask.text).format(
                        "MM/DD/YYYY"
                      )} at ${dayjs(task.completedTask.text).format(
                        "hh:mm A"
                      )}`}
                    </p>
                  )}
                </div>
              ) : task.taskType === "CHECK_OUT" ? (
                <div className="bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2.5">
                  <div className="flex items-center ">
                    <i
                      className={
                        "cursor-pointer " +
                        `${
                          task?.completedTask?.isCompleted
                            ? "fa-solid fa-circle-check text-green-500"
                            : "fa-regular fa-circle"
                        }`
                      }
                      onClick={() => {
                        if (
                          authRole === "CLIENT" ||
                          task?.completedTask?.isCompleted
                        )
                          return;
                        checkOutApi(task.completedTask?.id);
                      }}
                    ></i>
                    <p className="ml-3">Check out</p>
                  </div>
                  {task.completedTask?.completedBy && (
                    <p className="px-7 my-1 text-sm border-t pt-3">
                      Completed by{" "}
                      {`${task.completedTask?.completedBy.firstName} ${
                        task.completedTask?.completedBy.lastName
                      } on ${dayjs(task.completedTask.text).format(
                        "MM/DD/YYYY"
                      )} at ${dayjs(task.completedTask.text).format(
                        "hh:mm A"
                      )}`}
                    </p>
                  )}
                </div>
              ) : task.taskType === "CALL" ? (
                <div className=" bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2.5">
                  <div className="border-b-2 pb-2 flex items-center">
                    {task.completedTask?.isCompleted ? (
                      <i
                        className="fa-solid fa-circle-check text-xs cursor-pointer text-green-500"
                        onClick={() => {
                          if (authRole === "CLIENT") return;
                          updateTaskApi(
                            task.completedTask.id,
                            !task.completedTask?.isCompleted
                          );
                        }}
                      ></i>
                    ) : (
                      <i
                        className={`fa-regular fa-circle text-xs cursor-pointer`}
                        onClick={() => {
                          if (authRole === "CLIENT") return;
                          updateTaskApi(
                            task.completedTask.id,
                            !task.completedTask?.isCompleted
                          );
                        }}
                      ></i>
                    )}
                    <p className="ml-3 text-sm font-normal">Call Regarding</p>
                  </div>
                  <div className="ml-6 mt-2">
                    {task.phone && (
                      <>
                        <p className=" text-sm font-normal underline underline-offset-1">
                          Phone
                        </p>
                        <p className=" text-sm mt-1">{task.phone}</p>
                      </>
                    )}
                    {task.reason && (
                      <div className="mt-2">
                        <p className=" text-sm font-normal underline underline-offset-1">
                          Reason
                        </p>
                        <p className=" text-sm mt-1">{task.reason}</p>
                      </div>
                    )}
                  </div>
                </div>
              ) : task.taskType === "COMPLETE_CUSTOM_TASK" ? (
                <div className=" bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2.5">
                  <div className="border-b-2 pb-2 flex items-center">
                    <i
                      className={`fa-regular fa-circle text-xs cursor-pointer ${
                        task.isCompleted && "text-green-500"
                      }`}
                      onClick={() =>
                        updateTaskApi(task.id, !task.completedTask?.isCompleted)
                      }
                    ></i>
                    <p className="ml-3 text-sm font-normal">
                      Complete Custom Task
                    </p>
                  </div>
                  <div className="ml-6 mt-2">
                    {task.description && (
                      <>
                        <p className=" text-sm font-normal underline underline-offset-1">
                          Description
                        </p>
                        <p className=" text-sm mt-1">{task.description}</p>
                      </>
                    )}
                  </div>
                </div>
              ) : task.taskType === "DOWNLOAD_FILE" ? (
                <div className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2">
                  {task.completedTask?.isCompleted ? (
                    <i
                      className="fa-solid fa-circle-check text-xs cursor-pointer text-green-500"
                      onClick={() =>
                        updateTaskApi(
                          task.completedTask.id,
                          !task.completedTask?.isCompleted
                        )
                      }
                    ></i>
                  ) : (
                    <i
                      className={`fa-regular fa-circle text-xs cursor-pointer`}
                      onClick={() =>
                        updateTaskApi(
                          task.completedTask.id,
                          !task.completedTask?.isCompleted
                        )
                      }
                    ></i>
                  )}
                  <p className="ml-3 text-sm">Download File</p>
                </div>
              ) : task.taskType === "COLLECT_SIGNATURE" ? (
                "Collect signature"
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
        <div className="mt-5">
          <p>Post</p>
          {formattedTasks["Post"].map((task) => (
            <div
              className="flex items-center bg-[#F6F6F6] rounded-md py-2.5 px-3 mt-2"
              key={task.id}
            >
              {task.completedTask?.isCompleted ? (
                <i
                  className="fa-solid fa-circle-check text-xs cursor-pointer text-green-500"
                  onClick={() =>
                    updateTaskApi(
                      task.completedTask.id,
                      !task.completedTask?.isCompleted
                    )
                  }
                ></i>
              ) : (
                <i
                  className={`fa-regular fa-circle cursor-pointer`}
                  onClick={() =>
                    updateTaskApi(
                      task.completedTask.id,
                      !task.completedTask?.isCompleted
                    )
                  }
                ></i>
              )}
              <p className="ml-3 ">Enter closeout notes</p>
            </div>
          ))}
        </div>
      </div>
    </BaseCard>
  );
};

export default Tasks;
