import React, { useState } from "react";
import BaseButton from "../../components/ui/BaseButton";
import { brandPrimaryColor } from "../../utils/variables";
import BaseWrapper from "../../components/ui/BaseWrapper";
import useAuth from "../../hooks/useAuth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { declineOffer } from "../../services/api/offers";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../store/toast/toastSlice";
import CounterOfferModal from "./CounterOfferModal";
import RequestOfferModal from "./RequestOfferModal";
import MarkIncompleteModal from "./MarkIncompleteModal";
import {
  approveWorkOrder,
  draftWorkOrder,
  fetchUserAllowedAction,
  markWorkOrderComplete,
  markWorkOrderInComplete,
  updateWorkOrderApi,
} from "../../services/api/workOrder";
import { useParams } from "react-router-dom";
import { useUpdateWorkOrder } from "../../hooks/useUpdateWorkOrder";
import { updateTask } from "../../services/api/task";
import { handleGetLocation } from "../../utils/helpers";
import { checkIn } from "../../services/api/timeLog";
import ReadyToGoModal from "./ReadyToGoModal";
import CheckInModal from "./CheckInModal";
import CheckOutModal from "./CheckOutModal";
import CloseoutNotesModal from "./CloseoutNotesModal";

const ActionBar = () => {
  const { authRole, auth } = useAuth();
  const { updateWorkOrder } = useUpdateWorkOrder();
  const params = useParams();
  const [counterOfferModalOpen, setCounterOfferModalOpen] = useState(false);
  const [requestOfferModalOpen, setRequestOfferModalOpen] = useState(false);
  const [closeOutNotesModalOpen, setCloseOutNotesModalOpen] = useState(false);
  const [readyToGoModalOpen, setReadyToGoModalOpen] = useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useState(false);
  const [checkOutModalOpen, setCheckOutModalOpen] = useState(false);
  const [markIncompleteModalOpen, setMarkIncompleterModalOpen] =
    useState(false);
  const dispatch = useDispatch();
  const workOrderDetail = useSelector(
    (state) => state.workOrder.workOrderDetails
  );

  const {
    isLoading: isAllowedActionLoading,
    error: isAllowedActionError,
    data: userAllowedActionData,
    refetch: refetchUserAllowedAction,
  } = useQuery({
    queryKey: ["fetchUserAllowedAction"],
    queryFn: () => fetchUserAllowedAction(params.id),
  });

  console.log("userAllowedActionData", userAllowedActionData?.data);

  const {
    isPending: isUpdatingLoading,
    error: isUpdatingError,
    mutateAsync: updateWorkOrderApiMutation,
  } = useMutation({
    mutationFn: updateWorkOrderApi,
    onSuccess: (data) => {
      console.log("response ", data);
    },
  });

  const {
    // status,
    error: isDelineError,
    mutateAsync: declineOfferMutation,
  } = useMutation({
    mutationFn: declineOffer,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Job Updated Successfully",
          type: "success",
        })
      );
    },
  });

  const {
    isLoading: isMoveDraftLoading,
    error: isMoveDraftError,
    mutateAsync: moveDraftWorkOrderMutation,
  } = useMutation({
    mutationFn: draftWorkOrder,
    onSuccess: (data) => {
      dispatch(
        showToast({
          message: "Work Order Drafted Successfully",
          type: "success",
        })
      );
    },
  });

  const {
    isLoading: isMarkWorkOrderCompleteLoading,
    error: isMarkWorkOrderCompleteError,
    data: markWorkOrderCompleteData,
    refetch: reFetchMarkWorkOrderComplete,
  } = useQuery({
    queryKey: ["markWorkOrderComplete"],
    queryFn: () => markWorkOrderComplete(params.id),
    enabled: false,
  });

  const {
    isLoading: isWorkOrderApprovedLoading,
    error: isWorkOrderApprovedError,
    data: approveWorkOrderData,
    refetch: reFetchApproveWorkOrder,
  } = useQuery({
    queryKey: ["approveWorkOrder"],
    queryFn: () => approveWorkOrder(params.id),
    enabled: false,
  });

  async function handleNotInterestedButton(userId, workOrderId) {
    try {
      let payload = { workOrderId, status: "DECLINED" };
      await declineOfferMutation(payload);
    } catch (error) {
      dispatch(
        showToast({
          message: isDelineError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }
  async function handleMoveToDraftButton(workOrderId) {
    try {
      await moveDraftWorkOrderMutation(workOrderId);
      updateWorkOrder();
    } catch (error) {
      console.log(isMoveDraftError);
      dispatch(
        showToast({
          message: isMoveDraftError?.response?.data?.message,
          type: "error",
        })
      );
    }
  }

  const handlePublishButton = async () => {
    const payload = {
      id: workOrderDetail.id,
      status: "PUBLISHED",
    };
    try {
      await updateWorkOrderApiMutation(payload);
      updateWorkOrder();
      dispatch(
        showToast({
          message: "Work Order Published",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          message: error.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const markWorkOrderCompleteApi = async () => {
    try {
      await reFetchMarkWorkOrderComplete(params.id);
      updateWorkOrder();
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    }
  };

  const approveWorkOrderApi = async () => {
    try {
      await reFetchApproveWorkOrder(params.id);
      dispatch(
        showToast({
          message: "Successfully Approved",
          type: "success",
        })
      );
      updateWorkOrder();
    } catch (error) {
      dispatch(
        showToast({
          message: error.message,
          type: "error",
        })
      );
    }
  };

  const handleReadyToGo = async () => {
    setReadyToGoModalOpen(true);
  };

  const handleCheckIn = async (id) => {
    setCheckInModalOpen(true);
  };

  const handleCheckOut = async (id) => {
    setCheckOutModalOpen(true);
  };
  const handleCloseOutNotes = async () => {
    setCloseOutNotesModalOpen(true);
  };

  return (
    <div className="border-b border-zinc-300 py-[10px]">
      <BaseWrapper>
        <div className="grid grid-cols-9 gap-24">
          <div className="col-span-6 flex">
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[120px] mr-5 h-8"
              >
                Copy
              </BaseButton>
            )}

            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[160px] mr-5 h-8"
              >
                Save as Template
              </BaseButton>
            )}
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary  min-w-[120px] mr-5 h-8"
              >
                Tags
              </BaseButton>
            )}

            {authRole == "FREELANCER" &&
              userAllowedActionData?.data?.isRouted && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary   max-w-[140px] min-w-[140px] mr-5 h-8"
                  onClick={() =>
                    handleNotInterestedButton(auth.user.id, workOrderDetail.id)
                  }
                >
                  Not Interested
                </BaseButton>
              )}

            {authRole == "FREELANCER" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary  min-w-[120px]  max-w-[120px] mr-5 h-8"
              >
                Feedback
              </BaseButton>
            )}

            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
              className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary  min-w-[120px] max-w-[120px] mr-5 h-8"
            >
              Print
            </BaseButton>
            <BaseButton
              style={{
                border: `1px solid ${brandPrimaryColor}`,
                paddingTop: 0,
                paddingBottom: 0,
              }}
              className="bg-[#F6F6F6]  hover:bg-primary hover:text-white text-primary min-w-[150px] max-w-[150px] mr-5 h-8"
            >
              Report problem
            </BaseButton>
            {authRole === "CLIENT" && (
              <BaseButton
                style={{
                  border: `1px solid ${brandPrimaryColor}`,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
                className="bg-[#F6F6F6] hover:bg-primary hover:text-white text-primary   min-w-[120px] mr-5 h-8"
              >
                Cancel
              </BaseButton>
            )}
          </div>

          {authRole === "FREELANCER" && (
            <>
              {workOrderDetail?.status === "PUBLISHED" && (
                <div className="col-span-3 flex justify-end">
                  {!workOrderDetail?.isCounterOffersDisable &&
                    userAllowedActionData?.data.isCounter && (
                      <BaseButton
                        onClick={() => setCounterOfferModalOpen(true)}
                        style={{
                          border: `1px solid ${brandPrimaryColor}`,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        disabled={isMoveDraftLoading}
                        isLoading={isMoveDraftLoading}
                        className="bg-primary text-white  w-[150px] mr-5 h-8"
                      >
                        Counter Offer
                      </BaseButton>
                    )}
                  {userAllowedActionData?.data.isRequested && (
                    <BaseButton
                      onClick={() => setRequestOfferModalOpen(true)}
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      className="bg-primary text-white  w-[150px] h-8"
                    >
                      Request
                    </BaseButton>
                  )}
                </div>
              )}
              {workOrderDetail?.status === "ASSIGNED" && (
                <div className="col-span-3 flex justify-end">
                  <BaseButton
                    onClick={handleReadyToGo}
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    className="bg-primary text-white  w-[120px] mr-5 h-8"
                  >
                    Ready to go
                  </BaseButton>
                </div>
              )}
              <div className="col-span-3 flex justify-end">
                {workOrderDetail?.status === "CONFIRM" ||
                  (workOrderDetail?.status === "INCOMPLETE" && (
                    <BaseButton
                      onClick={handleCheckIn}
                      style={{
                        border: `1px solid ${brandPrimaryColor}`,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      className="bg-primary text-white  w-[120px] mr-5 h-8"
                    >
                      Check In
                    </BaseButton>
                  ))}
                {workOrderDetail?.status === "WORKDONE" && (
                  <BaseButton
                    className="py-[5px] px-2 mr-5"
                    color="primary"
                    fullWidth={false}
                    onClick={handleCloseOutNotes}
                  >
                    Enter Close out Notes
                  </BaseButton>
                )}
                {workOrderDetail?.status === "INPROGRESS" && (
                  <>
                    {userAllowedActionData?.data.isCheckOut ? (
                      <BaseButton
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          paddingRight: 0,
                        }}
                        loaderSize="24px"
                        loaderColor="primary"
                        className="bg-primary text-white min-w-[140px] mr-5 h-8"
                        fullWidth={false}
                        onClick={markWorkOrderCompleteApi}
                      >
                        Mark Complete
                      </BaseButton>
                    ) : (
                      <BaseButton
                        onClick={handleCheckOut}
                        style={{
                          border: `1px solid ${brandPrimaryColor}`,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        className="bg-primary text-white  w-[120px] mr-5 h-8"
                      >
                        Check out
                      </BaseButton>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          {authRole === "CLIENT" && (
            <div className="col-span-3 flex justify-end">
              {workOrderDetail?.status === "PUBLISHED" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  onClick={() => handleMoveToDraftButton(workOrderDetail.id)}
                  fullWidth={false}
                  className="bg-primary text-white ml-5 h-8 min-w-[150px]"
                >
                  Move to Draft
                </BaseButton>
              )}
              {workOrderDetail?.status === "PUBLISHED" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                >
                  Unpublish
                </BaseButton>
              )}
              {workOrderDetail?.status === "DRAFT" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                  onClick={handlePublishButton}
                >
                  Publish
                </BaseButton>
              )}
              {(workOrderDetail?.status === "ASSIGNED" ||
                workOrderDetail?.status === "CONFIRM" ||
                // workOrderDetail?.status === "INPROGRESS" ||
                workOrderDetail?.status === "WORKDONE") && (
                // || workOrderDetail?.status === "INCOMPLETE"
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white   min-w-[140px] ml-5 h-8"
                >
                  Leave Feedback
                </BaseButton>
              )}
              {workOrderDetail?.status === "WORKDONE" && (
                <BaseButton
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  loaderSize="24px"
                  loaderColor="primary"
                  className="bg-primary text-white min-w-[140px] ml-5 h-8"
                  onClick={() => setMarkIncompleterModalOpen(true)}
                >
                  Mark Incomplete
                </BaseButton>
              )}
              {workOrderDetail?.status === "WORKDONE" && (
                // || workOrderDetail?.status === "INCOMPLETE"
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                  disabled={isWorkOrderApprovedLoading}
                  isLoading={isWorkOrderApprovedLoading}
                  onClick={approveWorkOrderApi}
                >
                  Approve
                </BaseButton>
              )}{" "}
              {workOrderDetail?.status === "APPROVED" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                >
                  Modify Feedback
                </BaseButton>
              )}
              {workOrderDetail?.status === "PAID" && (
                <BaseButton
                  style={{
                    border: `1px solid ${brandPrimaryColor}`,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                  fullWidth={false}
                  className="bg-primary text-white  min-w-[140px] ml-5 h-8"
                >
                  Rate Provider
                </BaseButton>
              )}
              {(workOrderDetail?.status === "ASSIGNED" ||
                workOrderDetail?.status === "CONFIRM") &&
                workOrderDetail?.status !== "INPROGRESS" && (
                  <BaseButton
                    style={{
                      border: `1px solid ${brandPrimaryColor}`,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    fullWidth={false}
                    className="bg-primary text-white  min-w-[150px] ml-5 h-8"
                  >
                    Add Hold
                  </BaseButton>
                )}
            </div>
          )}
        </div>
      </BaseWrapper>

      <RequestOfferModal
        refetchUserAllowedAction={refetchUserAllowedAction}
        requestOfferModalOpen={requestOfferModalOpen}
        setRequestOfferModalOpen={setRequestOfferModalOpen}
      />
      <CounterOfferModal
        counterOfferModalOpen={counterOfferModalOpen}
        setCounterOfferModalOpen={setCounterOfferModalOpen}
      />
      <MarkIncompleteModal
        markIncompleteModalOpen={markIncompleteModalOpen}
        setMarkIncompleterModalOpen={setMarkIncompleterModalOpen}
      />
      <ReadyToGoModal
        readyToGoModalOpen={readyToGoModalOpen}
        setReadyToGoModalOpen={setReadyToGoModalOpen}
        tasks={workOrderDetail?.tasks}
      />
      <CheckInModal
        checkInModalOpen={checkInModalOpen}
        setCheckInModalOpen={setCheckInModalOpen}
        tasks={workOrderDetail?.tasks}
      />

      <CheckOutModal
        checkOutModalOpen={checkOutModalOpen}
        setCheckOutModalOpen={setCheckOutModalOpen}
        setCloseOutNotesModalOpen={setCloseOutNotesModalOpen}
        closeOutNotesModalOpen={closeOutNotesModalOpen}
        tasks={workOrderDetail?.tasks}
      />
    </div>
  );
};

export default ActionBar;
