import FindJobPage from "./pages/FindJobPages/FindJobPage";
import JobDetailsPage from "./pages/FindJobPages/JobDetailsPage";
import HomePage from "./pages/HomePage";
import ProfileDetailPage from "./pages/ProfilePages/ProfileDetailPage";
import CreateWorkOrderPage from "./pages/WorkOrderPages/CreateWorkOrderPage";
import WorkOrderPage from "./pages/WorkOrderPages/WorkOrderPage";

const routes = [
  { module: "HOME", path: "/", component: <HomePage />, isShow: false },
  {
    text: "Dashboard",
    icon: <i className="fa-solid fa-table-columns text-3xl"></i>,
    activeIcon: (
      <i className="fa-solid fa-table-columns text-3xl text-primary"></i>
    ),
    path: "/",
    childs: [
      {
        text: "Flightboard",
        path: "/",
      },
      {
        text: "Projects",
        path: "/projects",
      },
      {
        text: "Deliverables",
        path: "/deliverables",
      },
      {
        text: "Client Request",
        path: "/client-request",
      },
    ],
    isShow: true,
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/create",
    component: <CreateWorkOrderPage />,
    childs: [
      { path: "summary" },
      { path: "scheduling" },
      { path: "payment" },
      { path: "assessment" },
    ],
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/edit",
    component: <CreateWorkOrderPage />,
    childs: [
      { path: "summary" },
      { path: "scheduling" },
      { path: "payment" },
      { path: "assessment" },
    ],
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/edit/summary/:id",
    component: <CreateWorkOrderPage />,
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/edit/scheduling/:id",
    component: <CreateWorkOrderPage />,
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/edit/payment/:id",
    component: <CreateWorkOrderPage />,
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    path: "work-orders/edit/assessment/:id",
    component: <CreateWorkOrderPage />,
    isShow: false,
    requiredAccess: { isWrite: true },
  },
  {
    module: "WORK_ORDERS",
    text: "Work Order",
    path: "/work-orders",
    component: <WorkOrderPage />,
    icon: <i className="fa-solid fa-clipboard-list text-3xl"></i>,
    activeIcon: (
      <i className="fa-solid fa-clipboard-list text-3xl text-primary"></i>
    ),
    isShow: true,
    isShowClient: true,
    requiredAccess: { isRead: true },
  },
  {
    module: "JOBS",
    text: "Job",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/find-job",
    component: <FindJobPage />,
    isShow: true,
    isShowClient: false,
    requiredAccess: { isRead: true },
  },
  {
    module: "JOBS",
    text: "Job Details",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/find-job/:id",
    component: <JobDetailsPage />,
    isShow: false,
    requiredAccess: { isRead: true },
  },
  {
    text: "Field Force",
    icon: <i className="fa-solid fa-people-group text-3xl"></i>,
    activeIcon: (
      <i className="fa-solid fa-people-group text-3xl text-primary"></i>
    ),
    path: "/field-force",
    component: <>Tech Force</>,
    isShow: true,
  },
  {
    module: "ADMIN",
    path: "admin",
    component: <h1>Admin</h1>,
    isShow: false,
  },
  {
    module: "USERS",
    text: "Profile Detail",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/profile/details/:id",
    component: <ProfileDetailPage />,
    isShow: false,
    isShowClient: false,
    requiredAccess: { isRead: true },
  },
  {
    module: "USERS",
    text: "Profile Detail",
    icon: <i className="fa-solid fa-briefcase text-3xl"></i>,
    activeIcon: <i className="fa-solid fa-briefcase text-3xl text-primary"></i>,
    path: "/profile/details",
    component: <ProfileDetailPage />,
    isShow: false,
    isShowClient: false,
    requiredAccess: { isRead: true },
  },
];
export default routes;
