import * as React from "react";
import Rating from "@mui/material/Rating";

export default function BaseRating({
  readOnly = true,
  value = 3,
  size = "small",
  style,
}) {
  return (
    <Rating
      readOnly={readOnly}
      value={value}
      size={size}
      style={style}
      //   value={value}
      /* onChange={(event, newValue) => {
        setValue(newValue);
      }} */
    />
  );
}
