export function setDataInLocalStorage(key, payload) {
  localStorage.setItem(key, JSON.stringify(payload));
}

export function fetchDataFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function navigateWithReload(path) {
  window.location.href = path; // Navigate to the new route with a full reload
}

export function logout() {
  window.localStorage.clear();
  navigateWithReload("/company-signup");
}

export function debounce(func, delay) {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export function handleGetLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Resolve the promise with the position
          resolve(position.coords);
        },
        (error) => {
          // Reject the promise with an error
          switch (error.code) {
            case error.PERMISSION_DENIED:
              reject(new Error("User denied the request for Geolocation."));
              break;
            case error.POSITION_UNAVAILABLE:
              reject(new Error("Location information is unavailable."));
              break;
            case error.TIMEOUT:
              reject(new Error("The request to get user location timed out."));
              break;
            case error.UNKNOWN_ERROR:
              reject(new Error("An unknown error occurred."));
              break;
            default:
              reject(new Error("An unknown error occurred."));
          }
        }
      );
    } else {
      // If the browser doesn't support Geolocation

      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
}
